<template>
    <div class="w-full mt-8 p-1">
        <vx-card>
            <vs-row>
                <vs-col>
                    <h4 class="m-2">Cotizaciones</h4>
                    <h6 class="m-2">Generadas por vendedor</h6>
                </vs-col>
            </vs-row>
            <div v-if="showLoadingDiv">
      <div id="div-with-loading2" class="vs-con-loading__container mt-4">
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
      </div>
        <vue-apex-charts v-if="showChart" type="bar" height="380" :options="chartOptions" :series="series"></vue-apex-charts>
        </vx-card>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import gql from "graphql-tag";
    export default {
        data(){
            return{
                getDeveloper:[],
                allSellersNames:[],
                allSellersQuotes:[],
                showChart: false,
                showLoadingDiv: true,
                series: [
                    {data: [0, 0, 0],
                    name: 'Generadas'},
                    {data: [0, 0, 0, 0, 0, 0, 3]}
                    ],
                chartOptions: {
                    fill: {
                        colors:['#4285f4']
                    },
                    chart: {
                    type: 'bar',
                    height: 410
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                                },
                        }
                    },
                    dataLabels: {
                    enabled: true
                    },
                    xaxis: {
                    categories: [0 , 0 , 0],
                    }
                }
            }
        },
        apollo: {
            getDeveloper: {
            query: gql`
                query getDeveloperData($id: ID!) {
                getDeveloper(id: $id) {
                    _id
                    sellers_team{
                    _id
                    first_name
                    quotes{
                        _id
                    }
                    }
                }
                }
            `,
            variables() {
                return {
                id: localStorage.company_id
                };
            },
            pollInterval: 750
            }
        },
        components:{
            VueApexCharts
        },
        methods:{
            getAllSellersNames(){
               for (let i = 0; i < this.getDeveloper.sellers_team.length; i++) {
                   this.allSellersNames.push(this.getDeveloper.sellers_team[i].first_name)
               }
               this.sellersToGraph()
               this.getAllQuotesBySeller()
            },
            getAllQuotesBySeller(){
                for (let i = 0; i < this.getDeveloper.sellers_team.length; i++) {
                   this.allSellersQuotes.push(this.getDeveloper.sellers_team[i].quotes.length)
               }
            },
            sellersToGraph(){
                this.showChart = false
                this.chartOptions.xaxis.categories = this.allSellersNames
                this.series = [{
                    data: this.allSellersQuotes 
                }]
                this.showChart = true
                
            },
            openLoading(){
                this.$vs.loading({
                    container: '#div-with-loading2',
                    scale: 0.8,
                    type: "radius",
                    background: "transparent",
                    color: "#6b8afb",
                })
            },
        },
        mounted(){
            this.openLoading()
            setTimeout(() => {
        this.getAllSellersNames()
          this.$vs.loading.close('#div-with-loading2 > .con-vs-loading')
          this.showLoadingDiv = false
            }, 2000);
        }
    }
  
 
</script>
