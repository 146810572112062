<template>
  <div>
      <vx-card title="📊 Visualización rapida" title-color="black">
      <p class="mb-4">En esta sección ustede encontrara graficos básicos para ventas e inventarios.</p>
    </vx-card>

    <vs-row vs-w="12" vs-align="flex-start">
  <vs-col class="p-1" vs-type="flex" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="12" >
    <graficoInventario/>
  </vs-col>
  <vs-col class="p-1" vs-type="flex" vs-justify="center" vs-align="center" vs-lg="7" vs-sm="12" vs-xs="12">
    <graficoCotizaciones/>
  </vs-col>
</vs-row>
  </div>
</template>

<script>
import graficoInventario from '@/components/Graficos_Basicos/InventarioComponent'
import graficoCotizaciones from '@/components/Graficos_Basicos/EfectividadCotizaciones'
    export default {
        data(){
            return{

            }
        },
        components:{
            graficoInventario,
            graficoCotizaciones
        }
    }
</script>

