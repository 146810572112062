<template>
  <div class="w-full p-1">
    <div>
        <vx-card class="mt-8">
            <vs-row>
                <vs-col>
                    <h4 class="m-2">Inventario</h4>
                    <h6 class="m-2">Reservado vs Diponible</h6>
                </vs-col>
            </vs-row>

              <div v-if="showLoadingDiv" class="contained-example-container">
      <div id="div-with-loading" class="vs-con-loading__container mt-4">
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
      </div>
            
             <vue-apex-charts
                v-if="showChart"
                class="mt-8"
                type="bar"
                height="350"
                :options="barChart.chartOptions"
                :series="barChart.series"
            ></vue-apex-charts>
        </vx-card>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import gql from "graphql-tag";
export default {
  data() {
    return {
        getDeveloperData: [],
        showChart: false,
        showLoadingDiv: true,
      barChart: {
        series: [{
           data: [53, 32, 33, 52, 13, 44, 32],
            name: 'Reservados'
          }, {
              data: [44, 55, 41, 64, 22, 43, 21],
            name: 'Disponible'
          }],
        chartOptions: {
          chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true
              }
          },
          colors:['#19e180','#4285f4' ],
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          stacked: true,
          dataLabels: {
            enabled: true
          },
          xaxis: {
                categories: [
                    '50 - 74 m2',
                    '75 - 84 m2',
                    '85 - 94 m2',
                    '95 - 104 m2',
                    '105 - 114 m2',
                    '115 - 124 m2',
                    '125 - 134 m2',
                    '135 - 150 m2'
                ]
          }
        }
      }
    };
  },
  components: {
    VueApexCharts
  },
  apollo: {
    getDeveloper: {
      query: gql`
        query getDeveloperData($id: ID!) {
          getDeveloper(id: $id) {
            _id
            apartaments {
              _id
              living_square_mts
              actual_state
            }
          }
        }
      `,
      variables() {
        return {
          id: localStorage.company_id
        };
      },
      pollInterval: 750
    }
  },
  computed:{
      
      get50To74() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts <= 50 & i.living_square_mts <= 74)
      );
      return apartaments;
    },

    get50To74Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts <= 50 & i.living_square_mts <= 74 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },


    get75To84() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >= 75 & i.living_square_mts <= 84)
      );
      return apartaments;
    },

    get75To84Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >= 75 & i.living_square_mts <= 84 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },

    get85To94() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=85  & i.living_square_mts <= 94)
      );
      return apartaments;
    },

    get85To94Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=85  & i.living_square_mts <= 94 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },


    get95To104() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=95  & i.living_square_mts <= 104 )
      );
      return apartaments;
    },
    
    get95To104Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=95  & i.living_square_mts <= 104 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },
    
    get105To114() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=105  & i.living_square_mts <= 114)
      );
      return apartaments;
    },

    get105To114Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=105  & i.living_square_mts <= 114 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },

    get115To124() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=115  & i.living_square_mts <= 124)
      );
      return apartaments;
    },

    get115To124Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=115  & i.living_square_mts <= 124 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },

    get125To134() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=125  & i.living_square_mts <= 134)
      );
      return apartaments;
    },

    get125To134Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=125  & i.living_square_mts <= 134 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },

    get135To150() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=135  & i.living_square_mts <= 150)
      );
      return apartaments;
    },

    get135To150Reserved() {
      let apartaments = this.getDeveloper.apartaments.filter(
        i =>
          (i.living_square_mts >=135  & i.living_square_mts <= 150 & i.actual_state === 'Reservado')
      );
      return apartaments;
    },
    
    getGraficsData(){
      let data =  new Array (
          this.get50To74.length, 
          this.get75To84.length,
          this.get85To94.length,
          this.get95To104.length,
          this.get115To124.length,
          this.get125To134.length,
          this.get135To150.length
          )
          
      return data
    },
    getGraficsReservedData(){
      let data =  new Array (
          this.get50To74Reserved.length, 
          this.get75To84Reserved.length,
          this.get85To94Reserved.length,
          this.get95To104Reserved.length,
          this.get115To124Reserved.length,
          this.get125To134Reserved.length,
          this.get135To150Reserved.length
          )
          
      return data
    }
  },
  methods:{
      modifyCharts(){
         this.showChart = false
         this.barChart.series[1].data = this.getGraficsData
         this.barChart.series[0].data = this.getGraficsReservedData  
         this.showChart = true
      },
      openLoadingInDiv(){
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.8,
        type: "radius",
        background: "transparent",
        color: "#6b8afb",
      })
    },
  },
  
  mounted(){
    this.openLoadingInDiv()
      setTimeout(() => {
          this.modifyCharts()
          this.$vs.loading.close('#div-with-loading > .con-vs-loading')
          this.showLoadingDiv = false
      }, 2000);
  }
};
</script>

<style lang="scss" >

</style>